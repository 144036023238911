import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// Components
import { WidgetTableComponent } from './widget-table.component';
import { NgbdSortableHeaderDirective } from './sortable.directive';

@NgModule({
  declarations: [
    WidgetTableComponent,
    NgbdSortableHeaderDirective
  ],
  exports: [WidgetTableComponent],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingBarRouterModule
  ],
  providers: [DecimalPipe]
})
export class WidgetTableModule { }
