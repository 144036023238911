import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Components
import { WidgetMessageComponent } from './widget-message.component';

@NgModule({
    declarations: [WidgetMessageComponent],
    exports: [WidgetMessageComponent],
    imports: [
        CommonModule
    ]
})
export class WidgetMessageModule { }
