import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

// Components
import { WidgetMessageConfirmationComponent } from './widget-message-confirmation.component';

@NgModule({
    declarations: [WidgetMessageConfirmationComponent],
    exports: [WidgetMessageConfirmationComponent],
    imports: [
        CommonModule
    ]
})
export class WidgetMessageConfirmationModule { }
