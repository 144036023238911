import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router) { }

  @select('config') public config$: Observable<any>;

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    let stateGlobal = null;

    this.config$.subscribe(res => {
      if (res.dataUser && res.dataUser.token) {
        stateGlobal = true;
      } else {
        stateGlobal = false;
      }
    });


    if (stateGlobal) {
      return true;
    }

    this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
