import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';

// Components
import { WidgetToastComponent } from './widget-toast.component';

@NgModule({
  declarations: [WidgetToastComponent],
  imports: [
    CommonModule,
    ToastrModule.forRoot()
  ],
  exports: [WidgetToastComponent]
})
export class WidgetToastModule { }
