import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CarroService {
  private notificacionTareasSubject = new BehaviorSubject<any>(null);


  constructor(private socket: Socket,
  ) {

    this.socket.emit('joinRoom', 'administracion_bikerace');
    console.log(`Solicitado unirse a la sala: administracion_bikerace`);

    // NOTIFICACION INGRESO SOLICITUD CAMBIO CATEGORIA
    socket.fromEvent('entrada_solicitud_cambio_categoria').subscribe((data: any) => {
      console.log('Llegó una notificación de solicitud de cambio de categoría:', data);
      this.notificacionTareasSubject.next(data);
    });

    // NOTIFICACION MODIFICACION SOLICITUD CAMBIO CATEGORIA
    socket.fromEvent('modificacion_solicitud_cambio_categoria').subscribe((data: any) => {
      console.log('Llegó una notificación de modificacion solicitud de cambio de categoría:', data);
      this.notificacionTareasSubject.next(data);
    });

    // NOTIFICACION INGRESO SOLICITUD DEVOLUCION
    socket.fromEvent('entrada_solicitud_devolucion').subscribe((data: any) => {
      console.log('Llegó una notificación de solicitud de devolucion:', data);
      this.notificacionTareasSubject.next(data);
    });

    // NOTIFICACION MODIFICACION SOLICITUD DEVOLUCION
    socket.fromEvent('modificacion_solicitud_devolucion').subscribe((data: any) => {
      console.log('Llegó una notificación de modificacion solicitud de devolucion:', data);
      this.notificacionTareasSubject.next(data);
    });

    // NOTIFICACION INGRESO REVISION TRANSFERENCIA
    socket.fromEvent('entrada_revision_transferencia').subscribe((data: any) => {
      console.log('Llegó una notificación de revision transferencia:', data);
      this.notificacionTareasSubject.next(data);
    });


    // NOTIFICACION MODIFICACION REVISION TRANSFERENCIA
    socket.fromEvent('modificacion_revision_transferencia').subscribe((data: any) => {
      console.log('Llegó una notificación de modificacion revision transferencia:', data);
      this.notificacionTareasSubject.next(data);
    });

  }



  getNotificacionTareasObservable() {
    return this.notificacionTareasSubject.asObservable();
  }

}
