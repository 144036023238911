import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Location } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-widget-modal-container',
  templateUrl: './widget-modal-container.component.html'
})
export class WidgetModalContainerComponent implements OnInit, OnDestroy {

  // declaraciones
  public id: number;
  public destroy = new Subject<any>();
  public currentDialog = null;
  public back = null;

  constructor(
    private modalService: NgbModal,
    public location: Location,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.destroy.next(true);
    this.destroy.complete();
  }

  public setView(parameters: any, back = true, backdrop : boolean | "static" =  'static', keyboard = false ) {
    this.back = back;
    // se le pasa formulario a proyectar en modal
    this.currentDialog = this.modalService.open(parameters.component, { size: parameters.size ? parameters.size : 'xl', backdrop: backdrop, keyboard: keyboard });
    if (parameters.id) {
      this.currentDialog.componentInstance.id = parameters.id;
    }
    if (parameters.categoryId) {
      this.currentDialog.componentInstance.categoryId = parameters.categoryId;
    }
    if (parameters.regionId) {
      this.currentDialog.componentInstance.regionId = parameters.regionId;
    }
    if (parameters.data) {
      this.currentDialog.componentInstance.data = parameters.data;
      this.currentDialog.componentInstance.strings = parameters.strings;
    }

    this.currentDialog.componentInstance.currentDialog = this.currentDialog;

    // se regresa a la url anterior al modal
    this.currentDialog.result.then(result => {
      if (this.back === true) {
        this.location.back();
      }
    }, reason => {
      if (this.back === true) {
        this.location.back();
      }
    });
  }

}
