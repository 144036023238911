import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {select} from '@angular-redux/store';



@Injectable({
  providedIn: 'root'
})
export class AuthSuperadminGuard implements CanActivate {
  @select('config') public config$: Observable<any>;

  constructor(private route: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Observable<boolean>(obs => {
      this.config$.subscribe(dataStorage => {
        if (dataStorage.dataUser) {
          if (dataStorage.dataUser.role === 'Administrador') {
            obs.next(true);

          } else {
            this.route.navigate(['/auth']);
          }

        }
      });
    });
  }
}
