import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable()
export class CorredorService {

  // Observable refresh list data categorias
  flagRefresh = new BehaviorSubject<any>(null);
  observableData$ = this.flagRefresh.asObservable();

  constructor(private http: HttpClient) { }

  // actualizamos flag
  nextData(flag: boolean) {
    this.flagRefresh.next(flag);
  }

  obtenerCorredores() {
    return this.http.get<any>(`${environment.api}/corredor`);
  }

  obtenerIpsCorredores(eventoId:number) {
    return this.http.get<any>(`${environment.api}/corredor/ips/evento/${eventoId}`);
  }

  obtenerCorredoresSimplifyPorEvento(eventoId: number) {
    return this.http.get<any>(`${environment.api}/corredor/simplify/evento/${eventoId}`);
  }

  obtenerCorredoresPorEventoYCategoriaYEstadoYDesafio(idEvento: number, idCategoria, estado: string, desafio: string) {
    return this.http.get<any>(`${environment.api}/corredor/evento/${idEvento}/categoria/${idCategoria}/estado/${estado}/desafio/${desafio}`);
  }

  obtenerCorredoresPorEventoYCategoriaYOrganizadorYDesafio(idEvento: number, idOrganizador: number, idCategoria: number, desafio: string) {
    return this.http.get<any>(`${environment.api}/corredor/organizador/${idOrganizador}/evento/${idEvento}/categoria/${idCategoria}/desafio/${desafio}`);
  }

  obtenerCorredoresPorOrganizadorConLimite(idOrganizador: number, take: number) {
    return this.http.get<any>(`${environment.api}/corredor/organizador/${idOrganizador}/take/${take}`);
  }

  obtenerCorredoresPositionPorOrganizadorConLimite(idOrganizador: number, take: number) {
    return this.http.get<any>(`${environment.api}/corredor/organizador/${idOrganizador}/position/take/${take}`);
  }

  obtenerCorredoresConLimite(take: number) {
    return this.http.get<any>(`${environment.api}/corredor/take/${take}`);
  }

  obtenerCorredoresPorTransaccion(transaccionId: number) {
    return this.http.get<any>(`${environment.api}/corredor/transaccion/${transaccionId}`);
  }

  obtenerCorredoresPorOrganizador(idOrganizador: number) {
    return this.http.get<any>(`${environment.api}/corredor/organizador/${idOrganizador}`);
  }

  obtenerCorredorPorId(corredorId: number) {
    return this.http.get(`${environment.api}/corredor/${corredorId}`);
  }

  obteneresCorredorPorRut(rut: string) {
    return this.http.get(`${environment.api}/corredor/many/rut/${rut}`);
  }

  obtenerCorredorPorIdYUsuarioOrganizador(corredorId: number, idOrganizador: number) {
    return this.http.get(`${environment.api}/corredor/${corredorId}/organizador/${idOrganizador}`);
  }

  crearCorredor(form: any) {
    return this.http.post(`${environment.api}/corredor`, form);
  }


  copiarCorredores(form: any) {
    return this.http.post(`${environment.api}/corredor/copiar`, form);
  }

  traspasarCorredorExistente(form: any) {
    return this.http.post(`${environment.api}/corredor/traspasar-corredor-existente`, form);
  }

  verificarCorredores(form: any) {
    return this.http.post(`${environment.api}/corredor/verificar-corredores`, form);
  }

  traspasarCorredorNuevo(form: any) {
    return this.http.post(`${environment.api}/corredor/traspasar-nuevo-corredor`, form);
  }

  actualizarCorredor(corredorId: number, form: any) {
    return this.http.patch(`${environment.api}/corredor/${corredorId}`, form);
  }

  actualizarDor(corredorId: number, form: any) {
    return this.http.patch(`${environment.api}/corredor/update-dor/${corredorId}`, form);
  }

  actualizarTag(corredorId: number, form: any) {
    return this.http.patch(`${environment.api}/corredor/update-tag/${corredorId}`, form);
  }

  eliminarCorredor(corredorId: number) {
    return this.http.delete(`${environment.api}/corredor/${corredorId}`);
  }

  obtenerEstadisticaCorredoresPorEventoGraficoPorUsuarioConLimite(take: number, idOrganizador: number) {
    return this.http.get<any>(`${environment.api}/corredor/grafico/organizador/${idOrganizador}/take/${take}`);
  }

  obtenerEstadisticaCorredoresPorEventoGraficoConLimite(take: number) {
    return this.http.get<any>(`${environment.api}/corredor/grafico/take/${take}`);
  }

  actualizarTagDorImport(form: any) {
    return this.http.post(`${environment.api}/corredor/import-tag-dor`, form);
  }

}
