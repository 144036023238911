import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
// import { JwtInterceptor } from 'src/app/_helpers/jwt.interceptor';

// Components
import { WidgetUploadFileModalComponent } from './widget-upload-file-modal.component';

@NgModule({
  declarations: [WidgetUploadFileModalComponent],
  imports: [
    CommonModule,
    DropzoneModule,
  ],
  // providers: [
  //   {
  //     provide: HTTP_INTERCEPTORS,
  //     useClass: JwtInterceptor,
  //     multi: true
  //   }
  // ],
  exports: [WidgetUploadFileModalComponent]
})
export class WidgetUploadFileModalModule { }
