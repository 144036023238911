import { DevToolsExtension, NgRedux, NgReduxModule } from '@angular-redux/store';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule, } from '@ngrx/store';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgrxFormsModule } from 'ngrx-forms';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { reducers } from './app.reducer';

import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { AppRoutingModule } from './app-routing.module';
import { ThemeOptions } from './theme-options';
// Services
import { AuthenticationService } from './_services';
import { AuthGuard } from './_services/auth.guard';
import { JwtInterceptor } from './_services/jwt.interceptor';

// Components
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { FooterComponent } from './layout-app/footer/footer.component';
import { DotsComponent } from './layout-app/header/elements/dots/dots.component';
import { UserBoxComponent } from './layout-app/header/elements/user-box/user-box.component';
import { HeaderComponent } from './layout-app/header/header.component';
import { LayoutAppComponent } from './layout-app/layout-app.component';
import { LogoComponent } from './layout-app/sidebar/elements/logo/logo.component';
import { SidebarComponent } from './layout-app/sidebar/sidebar.component';
import { SinNotariaState, rootReducer } from './store';
import { ConfigActions } from './store/config.actions';

import { AngularFontAwesomeModule } from "angular-font-awesome";
import { ImageCropperModule } from 'ngx-image-cropper';
import { SocketIoConfig, SocketIoModule } from "ngx-socket-io";
import { environment } from "../environments/environment";
import { Encryptnterceptor } from './_services/encrypt.interceptor';
import { SolicitudCambioCategoriaService } from './_services/solicitud-cambio-categoria.service';
import { TareaService } from './_services/tarea.service';
import { EncryptService } from "./_shared/encrypt";
import { LocalStorageService } from './_shared/storage';
import { DevolucionService } from './_services/devolucion.service';
import { CorredorService } from './_services/corredor.service';
import { SolicitudCambioCategoriaRiderService } from './_services/solicitud-cambio-categoria-rider.service';
import { RiderService } from './_services/rider.service';
import { DevolucionRiderService } from './_services/devolucion-rider.service';
import { EventoBorradorService } from './_services/evento-borrador.service';
const config: SocketIoConfig = {
  url: `${environment.api}`, options: {
    transports: ['websocket'],

  }
};

registerLocaleData(localeEs);

@NgModule({
  declarations: [
    AppComponent,
    LayoutAppComponent,
    SidebarComponent,
    LogoComponent,
    HeaderComponent,
    DotsComponent,
    UserBoxComponent,
    FooterComponent,

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgReduxModule,
    // SlickCarouselModule,
    HttpClientModule,
    ComponentsModule,
    NgrxFormsModule,
    StoreModule.forRoot(reducers),
    DropzoneModule,
    Ng2SearchPipeModule,
    ImageCropperModule,
    AngularFontAwesomeModule,
    // provideFirebaseApp(() => initializeApp(environment.firebase)),
    // provideStorage(() => getStorage()),
    // AngularFireModule.initializeApp(environment.firebase),
    // AngularFireStorageModule
    SocketIoModule.forRoot(config)

  ],
  providers: [
    ConfigActions,
    ThemeOptions,
    AuthGuard,
    AuthenticationService,
    EventoBorradorService,
    TareaService,
    SolicitudCambioCategoriaService,
    SolicitudCambioCategoriaRiderService,
    CorredorService,
    RiderService,
    LocalStorageService,
    DevolucionService,
    DevolucionRiderService,
    EncryptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Encryptnterceptor,
      multi: true,
    },
    { provide: 'WINDOW', useValue: window },
    { provide: LocationStrategy, useClass: HashLocationStrategy },],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private ngRedux: NgRedux<SinNotariaState>,
    private devTool: DevToolsExtension
  ) {
    this.ngRedux.configureStore(
      rootReducer,
      {} as SinNotariaState,
      [],
      [devTool.isEnabled() ? devTool.enhancer() : (f) => f]
    );
  }
}
