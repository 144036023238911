import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ConfigActions } from '../store/config.actions';
import { Router } from '@angular/router';

@Injectable()
export class AuthenticationService {

  constructor(
    private http: HttpClient,
    private router: Router,
    public actionStore: ConfigActions) { }

  login(formLogin: any) {
    return this.http.post<any>(`${environment.api}/admin/login`, formLogin);
  }

  logout() {
    window.localStorage.clear();
    // eliminamos data de usuario en sesión
    const UPDATE_STATE = {
      userDataInSession: null,
      userDataCompleteInSession: null,
      dataUser: null,
      companies: null,
      workLike: null,
    };
    this.actionStore.updateConfig(UPDATE_STATE);
    this.router.navigate(['/auth/login']);
  }

}
