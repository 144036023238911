<div class="d-flex">
  <div class="header-btn-lg pr-0">
    <div class="widget-content p-0">
      <div class="widget-content-wrapper">
        <div class="widget-content-left">
          <div class="btn-group" placement="bottom-right">
            <button type="button" class="btn btn-link p-0 mr-2">
              <span>
                <div class="icon-wrapper icon-wrapper-alt rounded-circle" style="border-radius: 50% !important; width: 50px; height: 50px;">
                  <img *ngIf="userDataComplete.photoProfile !== null " width="51px" height="51px" [src]="userDataComplete.photoProfile" alt="">
                  <img *ngIf="userDataComplete.photoProfile === null " width="51px" height="51px" src="assets/images/logo.png" alt="">

                </div>
              </span>
            </button>

          </div>
        </div>
        <div class="widget-content-left ml-3 header-user-info">
          <div class="widget-heading">{{user.name}} - {{user.role}}</div>
          <div class="widget-heading">{{user.email}}</div>
        </div>
        <div class="widget-content-left ml-3">
          <button  type="button" class="btn btn-danger mr5" (click)="logout()">
            <em class="fa fa-power-off " title="Salir"></em>&nbsp; Salir
          </button>
        </div>

      </div>
    </div>
  </div>

</div>
