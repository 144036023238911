import { ConfigActions } from './config.actions';

const INITIAL_STATE = {
  headerTheme: 'bg-primary text-lighter',
  sidebarTheme: '',
  userDataInSession: null,
  userDataCompleteInSession: null,
  dataUser: null,
  companies: [],
  workLike: null,
  documents: [],
  showHiddenSidebar: false
};

export function ConfigReducer(state = INITIAL_STATE, action: any): any {

  switch (action.type) {
    case ConfigActions.CONFIG_GET:
      return Object.assign({}, state);

    case ConfigActions.CONFIG_UPDATE:
      return Object.assign({}, state, { ...action.payload });

    default:
      return state;
  }
}

