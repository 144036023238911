import { Component, OnInit } from '@angular/core';
import { ThemeOptions } from '../../../../theme-options';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';

// Services
import { AuthenticationService } from 'src/app/_services';
import {EncryptService} from "../../../../_shared/encrypt";

@Component({
  selector: 'app-user-box',
  templateUrl: './user-box.component.html',
})
export class UserBoxComponent implements OnInit {

  constructor(
    public globals: ThemeOptions,
    public authService: AuthenticationService,
    private encryptService: EncryptService
  ) {
  }

  // declaraciones
  public user = null;

  public userDataComplete = null;

  @select('config') public config$: Observable<any>;

  toggleDrawer() {
    this.globals.toggleDrawer = !this.globals.toggleDrawer;
  }

  ngOnInit() {

    this.config$.subscribe(store => {
      if (store.userDataCompleteInSession) {
        this.user = store.userDataCompleteInSession;
        this.userDataComplete = JSON.parse(this.encryptService.decryptData(localStorage.getItem('dataUserComplete')));
      }
    });
  }

  logout() {
    this.authService.logout();
  }

}
