import { combineReducers } from 'redux';
import { ConfigReducer } from './config.reducer';

export class SinNotariaState {
  config;
}

export const rootReducer = combineReducers<SinNotariaState>({
  config: ConfigReducer
});


