import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigActions } from './store/config.actions';
import {EncryptService} from "./_shared/encrypt";
import {CarroService} from "./_services/carro.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'bikerace-dashboard';
  public userDataComplete = null;
  public dataUser = null;
  public workLike = null;
  public documents = null;

  constructor(
    private router: Router,
    public actionStore: ConfigActions,
    private encryptService: EncryptService,
    private carroService: CarroService,
  ) { }

  ngOnInit(): void {
    this.userDataComplete = JSON.parse(this.encryptService.decryptData(localStorage.getItem('dataUserComplete')));
    this.dataUser = JSON.parse(this.encryptService.decryptData(localStorage.getItem('dataUser')));
    this.workLike = JSON.parse(this.encryptService.decryptData(localStorage.getItem('workLike')));
    this.documents = JSON.parse(this.encryptService.decryptData(localStorage.getItem('documents')));

    if (!this.userDataComplete) {
      this.router.navigate(['/auth/login']);
    } else {
      // guardamos data user en storage Redux
      const UPDATE_STATE = {
        userDataCompleteInSession: this.userDataComplete,
        workLike: this.workLike,
        dataUser: this.dataUser,
        documents: this.documents
      };
      this.actionStore.updateConfig(UPDATE_STATE);
    }


  }
}
